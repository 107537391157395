import React from "react"
import SectionHeader from "./section-header"
import SectionSegment from "./section-segment"

const SectionContent = ({
  sectionMetadata,
  sectionContent,
  allImageContent,
}) => {
  const headerTitle = sectionMetadata?.header || `Header`
  const headerClaim = sectionMetadata?.claim || `Claim`

  return (
    <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
      <SectionHeader title={headerTitle} description={headerClaim} />
      <content>
        {sectionContent.map((value, index) => {
          const uniqueKey = `section_key_${index}`
          const hasImageLeft = index % 2 === 1
          const contentHeader = value?.header || `Header`
          const contentDescription = value?.description || `Description`
          const contentImageName = value?.image || ``
          const contentImageDescription = value?.imageDescription || ``
          const contentImageFluid = allImageContent.edges.find(
            (element) =>
              element.node.childImageSharp.fluid.originalName ===
              contentImageName
          )

          return (
            <SectionSegment
              key={uniqueKey}
              hasImageLeft={hasImageLeft}
              header={contentHeader}
              description={contentDescription}
              image={contentImageFluid.node.childImageSharp.fluid}
              imageDescription={contentImageDescription}
            />
          )
        })}
      </content>
    </div>
  )
}

export default SectionContent

import { COLORS } from "../styles/constants"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { useMediaQuery } from "react-responsive"

const SectionSegment = ({
  hasImageLeft,
  header,
  description,
  image,
  imageDescription,
}) => {
  const headerView = (
    <SectionSegmentHeader header={header} description={description} />
  )
  const imageView = (
    <SectionSegmentImage image={image} imageDescription={imageDescription} />
  )
  const isMobile = useMediaQuery({ query: "(max-width: 752px)" })

  return (
    <>
      {isMobile ? (
        <>
          {headerView}
          {imageView}
        </>
      ) : hasImageLeft ? (
        <>
          {imageView}
          {headerView}
        </>
      ) : (
        <>
          {headerView}
          {imageView}
        </>
      )}
    </>
  )
}

const SectionSegmentHeader = ({ header, description }) => {
  return (
    <div>
      <h3>{header}</h3>
      <p style={{ color: COLORS.gray }}>{description}</p>
    </div>
  )
}

const SectionSegmentImage = ({ image, imageDescription }) => {
  return (
    <div>
      <Img fluid={image} alt={imageDescription} />
    </div>
  )
}

SectionSegment.defaultProps = {
  hasImageLeft: false,
  header: `Section Header`,
  description: `Section Description`,
  imageDescription: `Image Description`,
}

SectionSegment.propTypes = {
  hasImageLeft: PropTypes.bool,
  header: PropTypes.string,
  imageDescription: PropTypes.string,
  description: PropTypes.string,
}

export default SectionSegment

import CallToAction from "../components/cta"
import Hero from "../components/hero"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import SectionContent from "../components/section-content"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const ctaUrl = data.site.siteMetadata?.product?.ctaUrl || ``
  const productDescription =
    data.site.siteMetadata?.product?.description || `Product description`
  const email = data.site.siteMetadata?.email || `Email`
  const organization = data.site.siteMetadata?.organization || `Organization`
  const keywords = data.site.siteMetadata?.keywords || []

  return (
    <Layout email={email} organization={organization}>
      <SEO title={siteTitle} keywords={keywords} />
      <Hero
        siteTitle={siteTitle}
        ctaUrl={ctaUrl}
        productDescription={productDescription}
      />
      <SectionContent
        sectionMetadata={data.site.siteMetadata?.sectionContentMetadata}
        sectionContent={data.site.siteMetadata?.product?.features}
        allImageContent={data.allImageContent}
      />
      <CallToAction ctaUrl={ctaUrl} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        email
        organization
        keywords
        product {
          ctaUrl
          description
          features {
            header
            description
            image
            imageDescription
          }
        }
        sectionContentMetadata {
          header
          claim
        }
      }
    }
    allImageContent: allFile(
      filter: { extension: { eq: "png" }, sourceInstanceName: { eq: "images" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

import { COLORS } from "../styles/constants"
import PropTypes from "prop-types"
import React from "react"

const SectionHeader = ({ title, description }) => (
  <>
    <h2>{title}</h2>
    <p style={{ color: COLORS.mediumGray }}>{description}</p>
  </>
)

SectionHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SectionHeader

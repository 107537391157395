import React from "react"

const ButtonGooglePlay = ({ language }) => (
  <a href="https://play.google.com/store/apps/details?id=ch.pixeesoft.peyda&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    <img
      width="200px"
      alt="Nyní na Google Play"
      src="https://play.google.com/intl/en_us/badges/static/images/badges/cs_badge_web_generic.png"
    />
  </a>
)

export default ButtonGooglePlay

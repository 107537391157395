import DeviceImage from "./device"
import PropTypes from "prop-types"
import React from "react"
import headerImage from "../images/header.png"
import ButtonGooglePlay from "./button-google-play"

const Hero = ({ siteTitle, ctaUrl, productDescription }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>{siteTitle}</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>{productDescription}</p>
    <ButtonGooglePlay language={"cs"} />
    <div style={{ margin: 60, width: `250px`, position: "relative" }}>
      <DeviceImage />
    </div>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
  ctaUrl: PropTypes.string,
  productDescription: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
  ctaUrl: ``,
  productDescription: ``,
}

export default Hero

import Button from "../components/button"
import PropTypes from "prop-types"
import React from "react"
import SectionHeader from "./section-header"

const CallToAction = ({ ctaUrl }) => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Jste připraveni"
      description="Chcete být mezi prvními, kdo službu Peyda Track vyzkouší? Ozvěte se nám!"
    />
    <Button hyperlink={ctaUrl}>ZÍSKAT</Button>
  </div>
)

CallToAction.propTypes = {
  ctaUrl: PropTypes.string,
}

CallToAction.defaultProps = {
  ctaUrl: ``,
}

export default CallToAction

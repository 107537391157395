import "../styles/button.css"

import { BORDER_RADIUS, COLORS, GRADIENT } from "../styles/constants"

import React from "react"

const Button = ({ hyperlink, children }) => (
  <a href={hyperlink} target="_blank" rel="noreferrer">
    <button
      style={{
        padding: ".5rem 2.5rem",
        color: COLORS.lightWhite,
        fontWeight: 700,
        background: GRADIENT,
        borderRadius: BORDER_RADIUS,
        borderWidth: 0,
        cursor: "pointer",
      }}
    >
      {children}
    </button>
  </a>
)

export default Button
